
var $win = $(window);
var $doc = $(document);

function setDelaysForItems($items) {
	var delay = 0;

	$items.each(function() {
		var $item = $(this);

		$item.css({
			'transition-delay': delay / 10 + 's'
		});

		delay += 0.5;
	});
}

// Resize Helper
function resizeHelper () {
	if($win.width() < 769) {
		$('.slogan').removeAttr('style');
	}
}

;(function($, window, document, undefined) {

	$doc.ready(function() {

		$win.on('load resize', function () {
			if ($('.slogan').length) {
				var logoWidth = $('.logo').outerWidth();
				var sloganHeight = $('.main.home .section-socials, .main.home .section-socials').offset().top - $('.header').outerHeight();

				$('.slogan').width(logoWidth);

				$('.slogan').height(sloganHeight).slideDown('slow');

				// for (var i = 0; i <= sloganHeight; i++) {

				// 	$('.slogan').height(i);
				// 	setTimeout(function(){
				// 	}, 0.10);

				// }
			}

			resizeHelper();

			$(".main-inner .box").height('').equalizeHeight();
		});


		//  fix height on elements
		$.fn.equalizeHeight = function() {
			var maxHeight = 0, itemHeight;

			for (var i = 0; i < this.length; i++) {
				itemHeight = $(this[i]).height('auto').height();
				if (maxHeight < itemHeight) {
					maxHeight = itemHeight;
				}
			}
			return this.height(maxHeight);
		}

		//slide down pages
		$('.slideout').click(function(){
			var page = $(this).data('page');
			var current = $('.feature-list a.page-active').data('page');
			$('.feature-list a').removeClass('page-active');

			if(page!=current) {
				$(this).addClass('page-active');
				if(!current){
					//nothing open
					$('.page-' + page).slideDown();
				}else{
					$('.page-' + current).slideUp(400, function () {
						$('.page-' + page).slideDown();
					});
				}
			}else{
				//close the tab
				$('.page-' + current).slideUp(400);
			}
			return false;
		});


            $('.slider .slides').slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true
            });


        if ($('.slider-products-nav').length > 0) {
            $('.slider-products .slider-content .slides').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                asNavFor: '.slider-products-nav .slides'
            });

            $('.slider-products-nav .slides').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                centerMode: true,
                focusOnSelect: true,
                asNavFor: '.slider-products .slider-content .slides',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        } else {
			// no nav available...
            $('.slider-products .slider-content .slides').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true
            });
		}


		$('.grid').masonry({ 
			itemSelector: '.grid-item'  
		});
 
		$('.select-controls select').selectric();

		$('.navbar-nav>li>.dropdown-menu').each(function(index) {
			$(this).css({
				'margin-left': -$(this).width()/2
			}); 			
		});

		// $('.navbar-nav li.has-submenu > a').on('click', function (event) {
		// 	event.preventDefault();
		// 	$(this).parent().toggleClass('active');
		// });


		var $invisibleItems = $('.animate, .viewport');
		var timersInitialized = false;

	  
		$win.on('load resize scroll', function () { 

			var winT = $win.scrollTop();
			
			$invisibleItems.each(function() {
				var $invisibleItem = $(this);

				$invisibleItem.toggleClass('shown', inViewport($invisibleItem));


				if( $invisibleItem.hasClass('viewport') && inViewport($invisibleItem) && !timersInitialized ) {
					timersInitialized = true;
					var timeout = 0;

					//start counters
					for(var i=1;i<4;i++){
						var nr = $('.featured'+i).find('.icon span');
						var until = parseInt(nr.data('until'));
						var step = parseInt(nr.data('step'));
						var duration = parseInt(nr.data('duration'));
						//console.log(until,duration, step);
						nr.numerator({ toValue: until, duration: duration});
						timeout += 500;
					}
				}
			});
		});


		$win.on('load', function () { 
			setTimeout(function(){ 
				$('.grid-item').addClass('active')
			}, 20);
		 
		});
  
		setDelaysForItems($('.list-instagram-images li')); 
		setDelaysForItems($('.featured')); 
		setDelaysForItems($('.widget-intro')); 
		setDelaysForItems($('.project')); 
		setDelaysForItems($('.grid-item')); 

	 

	}); // jQuery end 


	function inViewport($element) {
		var winCenter = $win.scrollTop() + $win.height() / 1.1;

		return $element.offset().top <= winCenter;
	}

	

})(jQuery, window, document);
